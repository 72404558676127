<template>
    <h3>Reset Password</h3>
    <div class="loginContent">
        <div class="alert alert-success" role="alert" v-if="serverSucess">
            {{ serverSucess }}
        </div>
        <div class="alert alert-danger" role="alert" v-if="serverError">
            {{ serverError }}
        </div>    
        <p><strong>Enter your new password.</strong></p><br/>

         <form v-on:submit.prevent="onResetPassword()">
            <div class="item">
                    <label>Password</label>
                    <input class="form-control" minlength="6" pattern=".{6,}" title="6 characters minimum" :type="passwordType" placeholder="Your password" v-model.trim="password" autocomplete="off">
                    <p class="error" v-if="errors.password">{{ errors.password }}</p>
            </div>
            <div class="item">
                <label>Confirm Password</label>
                <input class="form-control" minlength="6" pattern=".{6,}" title="6 characters minimum" type="text" placeholder="Your password" v-model.trim="conf_password" autocomplete="off">

            </div>

            <button class="btn" type="submit" data-toggle="modal" data-target="#exampleModal">{{ $t('reset_password') }}</button>
            <router-link to="/">{{ $t('back_to_login') }}</router-link>

        </form>
    </div>
</template>

<script>
import {  mapMutations } from 'vuex'
import axiosInstance from "@/services/AxiosTokenInstance";
import { LOADING_SPINNER_SHOW_MUTATION } from '@/store/storeconstants';
export default {
  title () {
    return `Remote Assistance | ${this.title}`
  },  
    data(){
        return{
            title: 'Reset Pasword',
            passwordType: 'password',
            faClass: 'fa fa-eye-slash',
            password: '',
            conf_password:'',
            errors: [],
            serverError: '',
            serverSucess:'',
        }
    },
    methods: {
        ...mapMutations({
            showLoading: LOADING_SPINNER_SHOW_MUTATION,
        }),
        switchVisibility(){
            this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
            this.faClass = this.faClass === 'fa fa-eye-slash' ? 'fa fa-eye' : 'fa fa-eye-slash';
        },
        switchVisibilConf(){
            this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
            this.faClass = this.faClass === 'fa fa-eye-slash' ? 'fa fa-eye' : 'fa fa-eye-slash';
        },
        
        async onResetPassword(){
            this.serverError = '';
            this.serverSucess = '';
            this.userId = this.$route.params.userid;
            this.errors = [];            
            let bodyFormData = new FormData();
            bodyFormData.append('password', this.password);
            bodyFormData.append('conf_password', this.conf_password);
            bodyFormData.append('id_user', this.userId);

            let response = '';

            try{
                response = await axiosInstance.post(`reset-password`, bodyFormData);
                if(response.status == 200){ 
                    this.$router.push({path : '/'})  
                    this.serverSucess = 'Password has been succesfully changed';
                            
                }
                    else{
                    this.serverError = 'Pasword does not match!';

                }     
            }catch(error){
                this.disabled = false;
                this.serverError = error;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
 form {
     display: flex;
     flex-direction: column;
     gap: 1rem;

     .item {
        width: 100%;display: flex;
        flex-direction: column;
        gap: 0.2rem; 

         input {
             width: 100%;
             padding: 10px;
         }
     }
 }

 .btn {
     border-radius: 4px;
 }
 a {
     text-align: center;
 }
</style>